
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { saveAs } from "@/api/request/base";
import { countryNoComList } from "@/utils";
import Pagination from "@/comp/Pagination/index.vue";
import { userLogout, getUserManagerList, userFreeze, exportUserManagerList, userManagerIdentity } from "@/api/request/user";

//组件
@Component({
  name: "UserList",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private countryList: any[] = [];
  private listLoading: boolean = true;

  //请求数据
  private listQuery: any = {
    //页面数据
    row: 20,
    page: 0,

    //临时数据
    time: [],

    //时间数据
    end_time: "",
    start_time: "",

    //筛选数据
    country: "",
    nickname: "",
    excel: false,
    internel: -1,
    certified: -1,
    id: undefined,
    channel_id: undefined,
  };

  //日期数据
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //用户类型
  private interneloptions: any[] = [
    { value: -1, label: "全部用户类型" },
    { value: 0, label: "正常用户" },
    { value: 1, label: "内部人员" },
  ];

  //注册类型
  private certifiedoptions: any[] = [
    { value: -1, label: "全部注册类型" },
    { value: 0, label: "未实名" },
    { value: 1, label: "已实名" },
  ];

  //创建
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = false;
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }
    if (this.listQuery.id) this.listQuery.id = Number(this.listQuery.id);
    if (this.listQuery.channel_id) this.listQuery.id = Number(this.listQuery.channel_id);

    //获取数据
    const { data } = await getUserManagerList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理重置
  private handleReset(): void {
    //重置
    this.listQuery.time = [];
    this.listQuery.nickname = "";
    this.listQuery.end_time = "";
    this.listQuery.certified = -1;
    this.listQuery.start_time = "";
    this.listQuery.id = undefined;
    this.listQuery.excel = false;

    //获取数据
    this.getList();
  }

  //处理导出
  private async handleExcel() {
    //数据赋值
    this.listQuery.excel = true;

    //时间数据赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }
    if (this.listQuery.id) this.listQuery.id = Number(this.listQuery.id);
    if (this.listQuery.channel_id) this.listQuery.id = Number(this.listQuery.channel_id);

    //获取数据
    const { data } = await exportUserManagerList(this.listQuery);

    //保存数据
    this.listQuery.time && this.listQuery.time.length == 2 ? saveAs(data, `${this.listQuery.time[0]}--${this.listQuery.time[1]}用户列表`) : saveAs(data, "用户列表总览");
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //强制下线
  private handleoff(row: any): void {
    //显示提示
    this.$confirm(`您确定强制下线 ${row.nickname} `, "警告", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //请求数据
        const res = await userLogout({ userid: row.id });

        //下线成功
        if (res.status === 0) {
          //显示提示
          this.$message.success(`强制下线${row.nickname}成功`);

          //获取数据
          this.getList();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //性别筛选
  private sexFormatter(row: any): any {
    if (row.sex === 0) return "保密";
    if (row.sex === 1) return "男";
    if (row.sex === 2) return "女";
  }

  //处理修改类型
  private handleModify(row: any): void {
    //数据赋值
    this.modifyId = row.id;
    this.modifyType = row.identity;

    //显示对话框
    this.modifyDialog = true;
  }

  //处理冻结
  private handlefreeze(row: any): void {
    //显示提示
    this.$confirm(`您确定冻结 ${row.nickname} ？`, "警告", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //请求数据
        const res = await userFreeze({ userids: [row.id], status: 7 });

        //冻结成功
        if (res.status === 0) {
          //显示提示
          this.$message.success(`冻结${row.nickname}成功`);

          //获取数据
          this.getList();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //处理恢复
  private handlerecover(row: any): void {
    //显示提示
    this.$confirm(`您确定恢复 ${row.nickname} ？`, "警告", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //请求数据
        const res = await userFreeze({ userids: [row.id], status: 1 });

        //恢复成功
        if (res.status === 0) {
          //显示提示
          this.$message.success(`恢复${row.nickname}成功`);

          //获取数据
          this.getList();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //ID处理
  private idInputHandler(val: any): void {
    if (val.trim() == "") this.listQuery.id = undefined;
  }

  //实名处理
  private formattercertified(row: any): string {
    if (row.certified === 1) return "已实名";
    else return "未实名";
  }

  //渠道ID处理
  private channelIdInputHandler(val: any): void {
    if (val.trim() == "") this.listQuery.channel_id = undefined;
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }

  //------------------------------- 选择类型 -------------------------------
  //定义变量
  private modifyId: number = 0;
  private modifyType: number = 0;
  private modifyDialog: boolean = false;

  //用户类型
  private modifyOptions: any[] = [
    { value: 0, label: "正常用户" },
    { value: 1, label: "内部用户" },
    { value: 2, label: "运营账号" },
  ];

  //关闭类型
  private modifyClose(): void {
    //隐藏对话框
    this.modifyDialog = false;
  }

  //修改类型
  private modifyConfirm(): void {
    //显示提示
    this.$confirm(`确定修改？`, "警告", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //数据请求
        const res = await userManagerIdentity({ id: this.modifyId, identity: this.modifyType });

        //修改成功
        if (res.status === 0) {
          //显示提示
          this.$message.success(`修改类型成功`);

          //隐藏对话框
          this.modifyDialog = false;

          //获取数据
          this.getList();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
